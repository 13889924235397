<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2099_7544)">
      <path
        d="M18.3334 9.99999C18.3334 5.39761 14.6024 1.66666 10.0001 1.66666C5.39771 1.66666 1.66675 5.39761 1.66675 9.99999C1.66675 14.6023 5.39771 18.3333 10.0001 18.3333C14.6024 18.3333 18.3334 14.6023 18.3334 9.99999Z"
        :stroke="iconStroke"
        stroke-width="1.5"
      />
      <path
        d="M6.66675 10.625C6.66675 10.625 8.00008 11.3854 8.66675 12.5C8.66675 12.5 10.6667 8.12499 13.3334 6.66666"
        :stroke="iconStroke"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2099_7544">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '20',
    },
    width: {
      type: String,
      default: '20',
    },
    color: {
      type: String,
      default: 'primary-purple-600',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStroke() {
      return `var(--${this.color})`
    },
  },
}
</script>
